import { useAuth } from '@/modules/auth/api';
import downloadBlob from '@/utils/downloadBlob';
import useMedia from '@/compositions/media';
import { useObjects } from '@/modules/objects/compositions/objects';
import { computed } from '@vue/composition-api';
import { PROFILE_NOTIFICATION_ITEMS } from '@/utils/notificationItems';

export default function() {
  const { logout, user, notificationProfiles } = useAuth();
  const { getNotificationsHistory } = useMedia();
  const { resultList } = useObjects();

  const profiles = computed(
    () =>
      notificationProfiles.value?.object?.profiles?.map(item => item.profile) ||
      []
  );

  const accountMenuItems = computed(() => [
    {
      id: 'edit_profile',
      title: 'Edit account',
      icon: '$edit',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./EditAccount.vue')
        });
      }
    },
    {
      id: 'change_password',
      title: 'Change password',
      icon: '$password',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./ChangePassword.vue'),
          props: {
            userId: user.value?.id
          }
        });
      }
    },
    {
      id: 'program_settings',
      title: 'Program settings',
      icon: '$settings',
      action: ({ popup }) => {
        popup.open({
          component: () => import('./ProgramSettings.vue')
        });
      }
    },
    ...profiles.value.map(profile => ({
      id: profile.id,
      title: PROFILE_NOTIFICATION_ITEMS[profile.deliveryChannel].title,
      icon: PROFILE_NOTIFICATION_ITEMS[profile.deliveryChannel].icon,
      action: ({ popup }) => {
        popup.open({
          props: {
            profile,
            popupTitle:
              PROFILE_NOTIFICATION_ITEMS[profile.deliveryChannel].popupTitle
          },
          component: () => import('./NotificationSettings.vue')
        });
      }
    })),
    {
      id: 'notifications_download',
      title: 'Download notifications',
      icon: '$download',
      action: ({ popup }) => {
        popup.open({
          component: () =>
            import('@/components/history-list-card/HistoryDateRangePopup'),
          props: {
            onSubmit: async range => {
              const data = {
                from: `${range.from} 00:00:00`,
                to: `${range.to} 23:59:59`,
                ids: resultList.value.map(object => object.id)
              };
              await getNotificationsHistory(data).then(res => {
                downloadBlob(
                  res.data,
                  `PixelMonitor_NotificationsHistory_${range.from}-${range.to}.csv`,
                  res.data.type
                );
              });
            }
          }
        });
      }
    },
    {
      id: 'logout',
      title: 'Logout',
      icon: '$exit_to_app',
      action: async () => {
        await logout();
      }
    }
  ]);

  return {
    accountMenuItems
  };
}
