<template>
  <v-btn
    v-testid="`map-btn-view-toggle`"
    v-bind="$attrs"
    fab
    color="primary"
    @click="toggleView"
  >
    <v-icon v-text="icon" />
  </v-btn>
</template>

<script>
import { useToggleView } from '@/layouts/map/footerMenu/useToggleView';
import { computed } from '@vue/composition-api';

export default {
  name: 'ViewToggleButton',
  props: {},
  setup(props, { root }) {
    const { toggle: toggleView, isMapState } = useToggleView(root.$vuetify);

    const icon = computed(() => (isMapState.value ? '$view_toggle' : '$plan'));

    return {
      toggleView,
      icon
    };
  }
};
</script>

<style></style>
