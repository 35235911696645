<template>
  <v-menu
    v-model="show"
    left
    :offset-x="true"
    :max-height="350"
    :nudge-left="20"
    rounded
    :close-on-content-click="false"
  >
    <template #activator="{on}">
      <v-btn
        v-testid="`map-btn-geotag`"
        fab
        small
        color="white"
        class="text-caption"
        v-on="on"
      >
        <v-icon :color="iconColor" v-text="'$geotag'" />
      </v-btn>
    </template>
    <v-list dense>
      <v-text-field
        v-model="searchQuery"
        v-testid="`map-geotag-input-search`"
        hide-details
        solo
        flat
        placeholder="Search..."
        clearable
      />
      <v-divider />
      <div style="overflow: auto; max-height:280px;">
        <v-list-item
          v-for="(geotag, index) in filteredGeotags"
          :key="geotag.id"
          v-testid="`map-geotag-item-${index}`"
          @click="onGeotagClick(geotag)"
        >
          <v-list-item-content>
            <v-list-item-title
              :class="currentGeotag.id === geotag.id ? 'primary--text' : ''"
              >{{ geotag.name }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { computed, ref, watch } from '@vue/composition-api';
import { useMap } from '@/compositions/map';
import search from 'ol-ext/control/Search';

export default {
  name: 'GeotagsControl',
  methods: { search },

  setup(props, { root }) {
    const searchQuery = ref('');
    const show = ref(false);
    const {
      controlGeotagList,
      currentGeotag,
      setGeotag,
      ALL_GEOTAG_ID
    } = useGeotags();
    const { geotags } = useMap();

    const onGeotagClick = geotag => {
      const { id } = geotag;
      setGeotag(id);
      if (id !== ALL_GEOTAG_ID) {
        geotags.setGeotags([geotag], true);
        geotags.setMapToGeotagCenter(id);
      }
      show.value = false;

      root.$router.push({ name: 'objects' }).catch(() => {});
    };

    const iconColor = computed(() =>
      currentGeotag.value.id !== ALL_GEOTAG_ID ? 'primary' : undefined
    );

    const filteredGeotags = computed(() => {
      return (controlGeotagList.value || []).filter(
        geotag =>
          !searchQuery.value ||
          geotag.name?.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    watch(
      () => show.value,
      value => {
        if (!value) {
          // set timeout for prevent blink content areas
          setTimeout(() => {
            searchQuery.value = '';
          }, 150);
        }
      }
    );

    return {
      filteredGeotags,
      onGeotagClick,
      currentGeotag,
      iconColor,
      searchQuery,
      show
    };
  }
};
</script>
