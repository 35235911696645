<template>
  <v-list dense>
    <v-list-item
      v-for="item in accountMenuItems"
      :key="item.id"
      v-testid="`account-menu-item-${item.id}`"
      @click="handleClick(item)"
    >
      <v-list-item-action>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import useAccount from '@/modules/account/useAccount';
import { usePopup } from '@/compositions/popup';
import Vue from 'vue';

export default Vue.extend({
  name: 'AccountMenuList',
  setup(props, { root, emit }) {
    const { accountMenuItems } = useAccount();
    const popup = usePopup();
    const handleClick = item => {
      if (typeof item?.action === 'function') {
        emit('click');
        item.action({ item, popup, router: root.$router });
      }
    };
    return {
      handleClick,
      accountMenuItems
    };
  }
});
</script>

<style></style>
