var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"nudge-left":60,"max-height":300,"left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"testid",rawName:"v-testid",value:("map-btn-layers"),expression:"`map-btn-layers`"}],attrs:{"fab":"","small":"","color":"white","title":"Layers"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $layers ")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.plans),function(plan,index){return _c('v-list-item',{directives:[{name:"testid",rawName:"v-testid",value:(("map-layer-plan-" + index)),expression:"`map-layer-plan-${index}`"}],key:plan.id,on:{"click":function($event){_vm.$emit('update:selected-plan-ids', _vm.getSelectedPlanIds(plan.id))}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":(_vm.selectedPlanIds.includes(plan.id) && 'primary') || ''},domProps:{"textContent":_vm._s(
            _vm.selectedPlanIds.includes(plan.id)
              ? '$checkbox_checked'
              : '$checkbox_unchecked'
          )}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(plan.name)}})],1)}),(_vm.plans.length)?_c('v-divider'):_vm._e(),_vm._l((_vm.geoTypes),function(geoType,index){return _c('v-list-item',{directives:[{name:"testid",rawName:"v-testid",value:(("map-layer-geotype-" + index)),expression:"`map-layer-geotype-${index}`"}],key:geoType.id,on:{"click":function($event){!geoType.disabled && _vm.$emit('onGeoTypeSelect', geoType.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"disabled":geoType.disabled,"color":geoType.checked ? 'primary' : ''},domProps:{"textContent":_vm._s(
            geoType.checked ? '$checkbox_checked' : '$checkbox_unchecked'
          )}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(geoType.name)}})],1)}),_c('v-divider'),_vm._l((_vm.baseLayers),function(layer){return _c('v-list-item',{directives:[{name:"testid",rawName:"v-testid",value:(("map-base-layer-" + (layer.id))),expression:"`map-base-layer-${layer.id}`"}],key:layer.id,on:{"click":function($event){return _vm.$emit('update:selected-base-layer-id', layer.id)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":(layer.id === _vm.selectedBaseLayerId && 'primary') || ''},domProps:{"textContent":_vm._s(
            layer.id === _vm.selectedBaseLayerId
              ? '$radio_checked'
              : '$radio_unchecked'
          )}})],1),_c('v-list-item-content',{domProps:{"textContent":_vm._s(layer.name)}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }